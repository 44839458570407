p:last-child{
  margin-bottom: 0;
}

.main_wrapper {
  display: flex;
  flex-wrap: wrap;
  font-family: 'Poppins', sans-serif;
}

.left_pannel {
  width: 200px;
  padding: 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #dee2e6;
  /* height: 100vh;
  overflow: auto; */
}
.left_pannel::-webkit-scrollbar {
  width: 6px;
  background: #efefef;
}
.left_pannel::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.nav_pannel .logo {
  margin-bottom: 10px;
  padding: 20px;
}
.logout a {
  color: #fff;
  text-decoration: none;
  background: #d60000;
  border: 1px solid #d60000;
  display: block;
  padding: 7px;
  border-radius: 50px;
  transition: all .7s;
}
.logout a:hover {
  color: #d60000;
  background: #fff;
  transform: scale(1.09);
}
.main_content_panel {
  background: #f5f5f5;
  width: calc(100% - 200px);
  /* max-height: 100vh; */
  overflow: auto;
  padding: 30px;
  border-radius: 20px 0 0 20px;
  position: relative;
  min-height: 100vh;
}
/* .table_btn {
  background: #f9b015;
  border-radius: 4px;
  border: none;
  color: #fff;
}
.table_btn {
  margin: 4px;
} */
.logout {
  padding: 20px;
}
.custom_nav ul.navbar-nav {
  flex-direction: column;
  width: 100%;
}
.custom_nav ul.navbar-nav li {
  width: 100%;
  text-align: left;
}
.custom_nav ul.navbar-nav li a.nav-link {
  color: #888;
  padding: 12px 12px 12px 25px;
  position: relative;
  display: flex;
  align-items: center;
}
.custom_nav ul.navbar-nav li a.nav-link.active {
  color: #efb243;
}
.custom_nav ul.navbar-nav li a.nav-link img {
  width: 22px;
  margin-right: 10px;
}
.custom_nav ul.navbar-nav li a.nav-link.active img.normal_img {
  display: none;
}
.custom_nav ul.navbar-nav li a.nav-link img.normal_img {
  display: block;
}
.custom_nav ul.navbar-nav li a.nav-link.active img.active_img {
  display: block;
}
.custom_nav ul.navbar-nav li a.nav-link img.active_img {
  display: none;
}
.custom_nav ul.navbar-nav li a.nav-link.active::before {
  background: #efb243;
  content: "";
  width: 4px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.nav_pannel .profile_info {
  margin-bottom: 30px;
  background: transparent;
  padding: 30px 20px 20px 20px;
}

.profile_img {
  width: 120px;
  height: 120px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #ffffff;
  box-shadow: 0 0 0px 3px #faaf19;
}

.profile_info h2 {
  font-size: 18px;
  margin-top: 20px;
}

.profile_info p {
  font-size: 15px;
  color: #777;
  margin-bottom: 7px;
}

/**********/

.header_title {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header_title h1 {
  font-size: 30px;
  color: #78b1e0;
  margin-bottom: 0;
}
.header_title p {
  font-size: 20px;
  color: #777;
  margin-bottom: 0;
  font-weight: 500;
}
.header_title h1 span{
  font-weight: 300;
}

.dashboard_card {
  background: #fff;
  border-radius: 10px;
  padding: 22px;
  box-shadow: 0 0 13px #e2e2e2;
  height: 100%;
}
.intime {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.time_btn button {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 500;
  border: 2px solid #15bb66;
  color: #15bb66;
  background: #f5fffa;
  transition: all .7s;
}

.time_input p span {
  font-weight: 400;
}
.time_input p  {
  white-space: nowrap;
}

.time_input label {
  font-weight: 400;
  white-space: nowrap;
  
}

.time_input {
  font-size: 16px;
  /* font-weight: 600; */
  color: #777;
  line-height: 30px;
  text-align: right;
  width: 57%;
}
.btn_green:before {
  box-shadow: inset 0px 7px 28px #5dbb66;
}
.break_input p {
  font-size: 18px;
  color: #777;
  text-align: right;
}

.time_btn button.btn_red {
  border: 2px solid #cf191b;
  color: #cf191b;
  background: #fff5f5;
}
.time_btn button.btn_yellow {
  border: 2px solid #ffc107;
  color: #ffc107;
  background: #fff5f5;
}
.working_hours {
  text-align: center;
}
.working_hours p {
  font-size: 22px;
  color: #666;
}

button.req_btn {
  background: #79b2e1;
  border: 1px solid #79b2e1;
  padding: 7px 20px;
  border-radius: 6px;
  color: #fff;
  transition: all .7s;
}

button.req_btn:hover {
  background: #fff;
  color: #79b2e1;
}

.card_title {
  margin-bottom: 20px;
}

.card_title h4 {
  font-size: 24px;
  color: #666;
}

ul.important_date_list {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

ul.important_date_list li {
  display: flex;
  border-bottom: 1px solid #eee;
  padding: 10px 0;    
  align-items: center;
  font-size: 18px;
}

.date_item {
  background: #edf6ff;
  border-radius: 6px;
  margin-right: 10px;
  padding: 4px 8px;

}

.date_item h2 {
  font-size: 26px;
  margin-bottom: 0;
  color: #777;
}

.date_item h4 {
  font-size: 15px;
  color: #777;
  margin-bottom: 0;
}


.calender_heading {
  display: flex;
  justify-content: space-between;
}
.days {
  display: flex;
  flex-wrap: wrap;
}

.day {
  width: 14.28%;
  text-align: center;
}
.day.label {
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
}

.day span {
  width: 45px;
  height: 45px;
  display: inline-block;
  line-height: 45px;
  border-radius: 6px;
  background: #f9f9f9;
  margin-top: 20px;
  font-size: 18px;
  position: relative;
  cursor: pointer;
}

.day.holiday span {
  background: #6d6d6d;
  color: #fff;
}

.day span:after {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  top: 5px;
  right: 5px;
  border-radius: 50%;
  background: #000;
}

.day.holiday span:after {
  background: #fff;
}

.day.prenent span:after {
  background: #17bb65;
}

.day.leave span:after {
  background: #ff0000;
}

.day.present_day span {
  background: #ffecca;
}


.dropdown-toggle.calender_view::after {
  display: inline-block;
  margin-left: 7px;
  vertical-align: 3px;
  content: "";
  border-top: 1px solid transparent;
  border-right: 1px solid;
  border-bottom: 1px solid;
  border-left: 1px solid transparent;
  width: 10px;
  height: 10px;
  transform: rotate(
45deg
);
}

.calender_view {
  background: #edf6ff;
  color: #444;
  border: none;
  outline: none;
}

.calender_view:focus, .calender_view:hover  {
  background: #edf6ff;
  color: #444;
  border: none;
  box-shadow: none;
}


.weekly_calander ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.weekly_calander ul li {
  width: 14.28%;
  text-align: center;
}

.week_view {
  background: #f9f9f9;
  width: 70px;
  padding: 20px 10px;
  margin: 0 auto;
  border-radius: 6px;
  font-size: 18px;
}
.week_view span {
  width: 7px;
  height: 7px;
  background: #000;
  display: block;
  margin: 0 auto;
  border-radius: 50%;
}

.week_view.present span {
  background: #17bb65;
}

.week_view.leave span {
  background: #ff0000;
}

.week_view.holiday {
  background: #6d6d6d;
  color: #fff;
}

.week_view.holiday span{
  background: #fff;
}

.week_view.present_day {
  background: #ffecca;
}

footer p {
  text-align: center;
  margin-top: 30px;
  color: #777;
}

.Attendance_modification button.btn-close {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #fff;
  opacity: 1;
  border-radius: 50%;
  padding: 12px;
  box-shadow: 0 0 10px #999;
}

ul.day_info {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.day_info li {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.details_q {
  width: 40%;
}
.color_green{
  color: #15bb66;
}
.color_red{
  color: #ca0002;
}
.color_orange{
  color: #eeb242;
}
.color_blue{
  color: #86b1dd;
}
.text_green {
  color: #89c494;
}
.btn-submit{
background-color: #86b1dd !important;
color: #fff !important;
}
.time_btn {
  position: relative;
  width: 43%;
}
.time_btn button {
  display: block;
  background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7);
  background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7);
  background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7);
  position: relative;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  box-shadow: 0px 3px 8px #aaa, inset 0px 0px 13px #dadada;
}

.time_btn button:hover {
  box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff, 0 0 20px rgb(120 177 225 / 50%);
  transform: scale(1.03);
}

.time_btn button:before {
  content: "";
  display: block;
  position: absolute;
  top: -10px;
  left: -10px;
  bottom: -10px;
  right: -10px;
  z-index: 0;
  border-radius: 50%;
  box-shadow: inset 0px 7px 28px #eaeaea;
}
.time_btn .btn_green::before{
  box-shadow: inset 0px 7px 28px #15bb96;
}
.time_btn .btn_red::before{
  box-shadow: inset 0px 7px 28px #dc3545;
}
.time_btn .btn_yellow::before{
  box-shadow: inset 0px 7px 28px #ffc107;
}

.time_btn button.disabled{
  filter:grayscale(1);
  opacity:7;
  cursor: not-allowed;
}

.hide_mob{
  display: block;
}

.show_mob{
  display: none;
}


@media only screen and (min-width: 320px) and (max-width: 480px)  {
  button.btn_green{
    pointer-events: none;
    opacity: 0.4;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  button.btn_green{
    pointer-events: none;
    opacity: 0.4;
  }
}

.modal-backdrop.show {
  opacity: .7 !important;
}

.btn-leave_status {
  background: #78b1e1 !important;
  color: #fff !important;
  text-transform: uppercase;
  box-shadow: 3px 5px 10px #ccc;
  display: flex !important;
}

@media only screen and (max-width: 992px) {
 
  .main_content_panel {
    width: 100%;
    border-radius: 20px 20px 0 0;
  }
  .left_pannel {
    width: 100%;
    padding: 10px 15px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .logo img {
    height: 40px;
  }

  .profile_info {
    margin-bottom: auto;
  }
  .profile_img {
    width: 180px;
    height: 180px;
    margin: 0px auto 20px;
    box-shadow: 0 0 0px 2px #faaf19;
  }
  .profile_info h2 {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 400;
  }
  .logout a {
    font-size: 16px;
    margin-left: 0px;
    padding: 6px 20px;
}
.logout {
  display: flex;
}
  .logout a i {
    color: #fff !important;
    font-size: 20px;
    padding: 2px 3px;
  }
  .filter_letters ul li {
    width: 7.69%;
  }
  .employee_table {
    overflow: auto;
  }
  .filter_letters ul {
    flex-wrap: wrap;
  }
  .logout a:hover i {
    color: #d60000 !important;
  }

  .employee_count h2 {
    font-size: 38px;
    width: 90px;
    height: 90px;
    margin: 0 auto 10px;
  }

  .dashboard_card {
    padding: 15px;
  }
  .dashboard_card a {
    text-decoration: none;
  }
  .dashboard_card.employee_lists {
    background: #f3f7fb;
    padding: 0;
  }
  .dashboard_card.employee_lists .card_title.calender_heading {
    background: #fff;
    padding: 30px;
    border-radius: 10px 10px 0 0;
    margin-bottom: 0;
  }
  .dashboard_card.employee_lists .card_title.calender_heading {
    padding: 15px;
    flex-direction: column;
  }
  .dashboard_card.employee_lists .card_title.calender_heading h4 {
    margin-bottom: 20px;
  }
  .dashboard_card.employee_lists .card_title.calender_heading .d-flex button {
    white-space: nowrap;
  }

  .logout a span svg {
    font-size: 16px;
  }
  .profile_logout {
    min-height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
.hide_mob{
  display: none;
}
.show_mob{
  display: block;
}

.nav_pannel {
  width: 100%;
}
.nav_pannel .logo {
  margin-bottom: 0px;
  padding: 0px;
}
.navbar-light .navbar-toggler {
  color: rgb(250 175 24) !important;
  border-color: transparent !important;
  outline: none;
}
.header_title {
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.header_title h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

}
.profile-pic {
  color: transparent;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.3s ease;
}
.profile-pic input {
  display: none;
}
.profile-pic img {
  position: absolute;
  object-fit: cover;
  width: 165px;
  height: 165px;
  box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
  border-radius: 100px;
  z-index: 0;
}
.profile-pic .-label {
  cursor: pointer;
  height: 165px;
  width: 165px;
}
.profile-pic:hover .-label {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000;
  color: #fafafa;
  transition: background-color 0.2s ease-in-out;
  border-radius: 100px;
  margin-bottom: 0;
}
.profile-pic span {
  display: inline-flex;
  padding: 0.2em;
  height: 2em;
}

@media only screen and (max-width: 992px) {
  .dashboard_card.employee_lists .employee_table th {
    white-space: nowrap !important;
}
  .nav_pannel .profile_info {
    text-align: center;
}
  .intime {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .time_btn {
    width: 100%;
}
.time_btn button {
  margin: 20px auto;
}
.time_input {
  width: 90%;
}
.fc .fc-toolbar-title {
  font-size: 18px !important;
  margin: 0 !important;
}
.fc .fc-button {
  padding: 2px 5px !important;
}
.fc .fc-col-header-cell-cushion {
  padding: 2px 2px !important;
  font-size: 14px !important;
}
}

.leaves_remain {
  background: #75c690;
  color: #fff;
  padding: 7px 15px;
  border-radius: 4px;
  font-size: 18px;
}

.my_profile_card {
  background: #fff;
  border-radius: 10px;
  padding: 22px;
  box-shadow: 0 0 13px #e2e2e2;
  height: 95%;
}

.profile_img {
  position: relative;
  overflow: visible;
}

.profile_img img {
  border-radius: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  height: calc(100% + 60px);
  width: auto !important;
  transform: translate(-50%, -50%);
}

.cake_icon {
  position: absolute;
  right: 0px;
  bottom: 0px;
  background: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: rgb(17 17 26 / 10%) 0px 8px 24px, rgb(17 17 26 / 10%) 0px 16px 56px, rgb(17 17 26 / 10%) 0px 24px 80px;
}
.cake_icon img{
  width: 20px;
  border-radius: 0;
}

span.celebration_link  {
  position: relative;
}

span.celebration_link a {
  color: #666;
  font-size: 20px;
  font-weight: 200;
}

span.divider {
  margin: 0 20px;
  font-weight: 300;
}

span.red_dot {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #d60001;
  border-radius: 50%;
  box-shadow: 0 0 10px #d60001;
  top: -5px;
}

h3.list_title {
  color: #b5000d;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 15px;
}
ul.employe_celebrations {
  list-style: none;
  padding: 0;
}

ul.employe_celebrations li {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
  font-size: 20px;
}
.thoughtof_day_wrapper {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}
.thought_box {
  width: 100%;
}
.thoughtof_day_wrapper span {
  transform: rotateZ(
270deg);
  white-space: nowrap;
  width: 30px;
}

.thought_box .dashboard_card {
  background: rgb(239,178,66);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.content_container {
  width: 90%;
  margin: 0 auto;
  text-align: center;
}
.content_container h2 {
  font-size: 22px;
  margin-bottom: 0;
  line-height: 33px;
  font-style: italic;
  color: white;
  text-shadow: 0 2px 12px #000;
}
.thought_box {
  width: calc(100% - 50px);
  margin-left: auto;
}

.thoughtof_day_wrapper span {
  transform: rotateZ( 
270deg);
  white-space: nowrap;
  width: auto;
  height: 50px;
  position: absolute;
  left: -45px;
  text-align: center;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    min-width: 138px;
    text-transform: uppercase;
}

.top_design_element {
  margin-bottom: 20px;
  position: relative;
}

.top_design_element img {
  width: 80px;
  position: relative;
}

.top_design_element:before {
  content: "";
  width: 40%;
  height: 2px;
  background: #758871;
  position: absolute;
  top: 50%;
  left: 0;
}
.top_design_element:after {
  content: "";
  width: 40%;
  height: 2px;
  background: #758871;
  position: absolute;
  top: 50%;
  right:  0;
}



#balloon-container {
  height: 100vh;
  padding: 1em;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  position: absolute;
  z-index: 9999;
}
.balloon {
  height: 125px;
  width: 105px;
  border-radius: 75% 75% 70% 70%;
  position: relative;
}

.balloon:before {
  content: "";
  height: 75px;
  width: 1px;
  padding: 1px;
  background-color: #FDFD96;
  display: block;
  position: absolute;
  top: 125px;
  left: 0;
  right: 0;
  margin: auto;
}

.balloon:after {
    content: "▲";
    text-align: center;
    display: block;
    position: absolute;
    color: inherit;
    top: 120px;
    left: 0;
    right: 0;
    margin: auto;
}

@keyframes float {
  from {transform: translateY(100vh);
  opacity: 1;}
  to {transform: translateY(-300vh);
  opacity: 0;}
}


canvas {
  display: block;
  position:absolute !important;
}

.profile_img .profile_img_div {
  height: 114px;
  width: 114px;
  overflow: hidden;
  border-radius: 100%;
  position: relative;
}

.dropdown_icon {
  display: flex;
  align-items: center;
  position: relative;
}
.dropdown_icon:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  position: absolute;
  right: 5px;
}
.rounded-50 {
  border-radius: 50px;
}

.has-search .form-control {
  padding-right: 2.375rem;
  border-radius: 50px;
  color: #777;
}
.form-group.has-search {
  position: relative;
}
.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  right: 0;
}
.filter_letters {
  background: #fff;
  border-radius: 0 0 10px 10px;
  padding: 0 30px 30px;
}

.filter_letters ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}

.filter_letters ul li a {
  text-decoration: none;
  color: #777;
  font-size: 16px;
  font-weight: 500;
}
.filter_letters ul li:hover a,
.filter_letters ul li.active a {
  color: #efb243;
}
.employee_table {
  padding: 30px;
}
.employee_table {
  overflow: auto;
}
.employee_table th {
  white-space: nowrap;
}
.employee_table table th:last-child {
  white-space: normal;
}
.textCenter {
  text-align: center;
}
.user_profile {
  padding: 25px;
  border-right: 1px solid #eee;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.user_profile .profile_img {
  border: 2px solid #fff;
  box-shadow: rgb(0 0 0 / 15%) 0px 5px 15px 0px;
}
.profile_img {
  width: 120px;
  height: 120px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #ffffff;
  box-shadow: 0 0 0px 3px #faaf19;
}
.user_profile .profile_img {
  border: 2px solid #fff;
  box-shadow: rgb(0 0 0 / 15%) 0px 5px 15px 0px;
}
/* .profile_img {
  width: 180px;
  height: 180px;
  margin: 0 0px 0px 0;
  box-shadow: 0 0 0px 2px #faaf19;
} */
.admin_heading {
  display: flex;
  justify-content: space-between;
  padding: 25px 25px 0px 0;
}
.admin_heading {
  padding: 25px 25px 0px 25px;
}
.leaves_remain {
  background: #75c690;
  color: #fff;
  padding: 7px 15px;
  border-radius: 4px;
  font-size: 18px;
}
.pending_leaves {
  display: inline-block;
  cursor: pointer;
  /* background: #9fd6b1; */
  text-align: center;
}
.edit_pending_leaves {
  width: 50px;
  text-decoration: underline;
  display: inline-block;
  cursor: pointer;
  background: #9fd6b1;
  text-align: center;
}
.time_spend h4 {
  color: #666;
  font-size: 18px;
}
.time_spend {
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
  padding: 0px 25px 15px;
}
span.timein {
  color: green;
}
span.timeout {
  color: red;
}
.table_btn {
  background: #f9b015;
  border-radius: 4px;
  border: none;
  color: #fff;
  margin: 4px;
}
.admin_heading {
  display: flex;
  justify-content: space-between;
  padding: 25px 25px 0px 0;
}
.time_cards {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  justify-content: space-between;
  padding-right: 20px;
}
.time_cards {
  padding-right: 20px;
  padding-left: 20px;
}
.edit_btn {
  position: absolute;
  right: 20px;
  top: 20px;
}
.time_card_base {
  box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px,
    rgb(0 0 0 / 30%) 0px 30px 60px -30px,
    rgb(10 37 64 / 35%) 0px -2px 6px 0px inset;
  width: calc(33.33% - 20px);
  padding: 20px;
  position: relative;
}
.leave_status {
  display: flex;
  align-items: center;
  padding-top: 10px;
}
span.request_empty {
  width: 22px;
  height: 22px;
  background: green;
  display: inline-block;
  margin: 0;
  border-radius: 50%;
}
span.request_empty.break_status {
  background: yellow;
}
span.request_empty.request_rised {
  background: #ff0000;
}





@media screen and (max-width: 767px) {
  .user_profile {
    padding: 15px;
    border-right: none;
    border-bottom: 1px solid #eee;
  }
  .admin_heading {
    padding: 15px 15px 10px 15px !important;
    flex-direction: column;
    text-align: center;
  }
  .time_spend {
    text-align: center;
    padding: 0 15px 15px;
  }
  .time_cards {
    justify-content: space-between;
    padding-right: 15px;
    padding-left: 15px;
  }
  .time_card_base {
    width: 100%;
    margin-bottom: 30px;
  }
  .time_card_base:last-child {
    margin-bottom: 0;
  }
  .time_card_base {
    box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px,
      rgb(0 0 0 / 30%) 0px 30px 60px -30px,
      rgb(10 37 64 / 35%) 0px -2px 6px 0px inset;
    width: calc(33.33% - 20px);
    padding: 20px;
    position: relative;
  }
  .time_card_base h3 {
    text-transform: uppercase;
    font-size: 24px;
    margin-bottom: 20px;
  }
  .time_card_base p {
    font-size: 18px;
    font-weight: 500;
    color: #666;
  }
  .leave_status h5 {
    width: 100% !important;
    margin-bottom: 15px !important;
    font-size: 14px;
    margin-right: 0 !important;
  }
  .leave_status {
    flex-direction: column;
    text-align: center;
  }
  .leave_status {
    display: flex;
    align-items: center;
    padding-top: 10px;
  }
  .leave_btns {
    display: flex;
  }
  .calender_heading .btn-groups {
    display: flex;
  }
  .modal-body .form-control {
    margin-bottom: 15px;
  }
}

/* Notification cSs */
.user-notify-data {
  font-size: 16px;
}
.user-name-wrap {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.user-notify-data {
  font-size: 16px;
}
.user-name-info {
  padding-right: 0.25em;
}
.app-header ul li {
  list-style: none;
  position: relative;
}
.user-notify-data .app-notification__title {
  padding: 8px 20px;
  text-align: center;
  background-color: rgb(249 175 22);
  color: #333;
  font-size: 14px;
  font-weight: 400;
}
.user-notify-data .app-notification__content {
  max-height: 220px;
  overflow-y: auto;
}
.app-header a {
  text-decoration: none;
  color: inherit;
}
.user-notify-data .app-notification__item {
  display: flex;
  padding: 8px 0px;
  color: inherit;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s ease;
}
.user-notify-data .app-notification__icon {  
  padding-right: 5px;
}
.app-notification__message, .app-notification__meta {
  margin-bottom: 0;
}
.user-notify-data .app-notification__meta {
  color: #6c757d !important;
  font-size: 12px;
}
.user-notify-data .app-notification__message {
  line-height: 1.2;
  font-size: 14px;
  color: #333;
  font-size: 13px;
    margin-bottom: 3px;
}

.user-notify-data .dropdown-menu:after {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  right: 10px;
  border: 8px solid #fff;
  border-color: transparent transparent #faaf19 #f9af16;
  transform: translateX(-50%) rotate(135deg);
  box-shadow: -2px 2px 3px rgb(57 73 76 / 10%);
  z-index: 9999;
}
.user-notify-data .app-notification {
  top: 10px !important;
  transform: translate(-200px, 35px) !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  padding-top: 0px;
}


/* new added */

.app-notification__content::-webkit-scrollbar {
  width: 6px;
}

.app-notification__content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}
.app-sidebar::-webkit-scrollbar {
  width: 6px;
}

.app-sidebar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

.app-notification {
  padding-top: 0;
}
.app-notification__item, .app-notification__item a {
  text-decoration: none;
}
.user-notify-data .app-notification__content li {
  padding: 0 5px;
}
.app-notification__icon .text-primary {
  color: #7ab3e1!important;
}


.nav_pannel .logo,.nav_pannel .profile_info{
  margin-bottom: 0;
}
.nav_pannel .logo{ 
  padding: 10px;
}
.nav_pannel .profile_info{
  padding: 20px 15px 15px;
}
#navbarTogglerDemo01 {
  margin-top: 0 !important;
}
.custom_nav ul.navbar-nav li a.nav-link {
  padding: 8px 8px 8px 20px;
}

.profile_img  img {
  object-fit: cover;
  width: 100%;
  height: 118px;  
}

.discussions-list-main {
  max-height: 285px;
  overflow: auto;
}
.last-project-updates-main {
  max-height: 160px;
  overflow: auto;
}

@media screen and (max-width:1399px){
  .working_hours p {
    font-size: 18px;
}
.time_input {
  width: 50%;
}
.time_btn {
  width: 50%;
}
}
