@media only screen and (min-width: 768px) {
  .loader {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgb(76 76 76 / 20%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .loader {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgb(76 76 76 / 20%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
}
